import './Like.css';
import StarRating from './StarRating';

function Like({ author, stars, message }) {


  return (
    <div className="Like">
      <div className='author'>{author}</div>
      <div className='stars'>
        <StarRating score={stars} />
      </div>
      <div className='message'>{message}</div>
      
    </div>
  );
}

export default Like;
