import { useEffect, useState } from 'react';
import './ActivityDetail.css';
import whiteTriangle from '../../../asset/image/triangle_10.svg';
import triangle5 from '../../../asset/image/triangle_5.svg';
import triangle6 from '../../../asset/image/triangle_6.svg';
import triangle7 from '../../../asset/image/triangle_7.svg';
import NavBar from '../navbar/NavBar';
import blueRectangle from '../../../asset/image/blue_rectangle.svg';
import linePattern1 from '../../../asset/image/line_pattern_bg_1.png';
import linePattern2 from '../../../asset/image/line_pattern_bg_2.png';

import phoneIcon from '../../../asset/image/phone.svg';
import mailIcon from '../../../asset/image/mail.svg';
import fbIcon from '../../../asset/image/fb_f.svg';
import ParallaxImage from '../parallax_image/ParallaxImage';
import Services from '../services/Services';

function ActivityDetail({ title, subtitle, image, contentTitle, videoUrl, children, mainImageThreshold }) {
  const [delta, setDelta] = useState(0);
  const [navbarFixed, setNavbarFixed] = useState(true);
  const [navbarBackground, setNavbarBackground] = useState(false);

  var dlt = 0;

  useEffect(() => {
    const handleScroll = event => {
      let newDelta = window.scrollY / 4;
      let newNavbarBackground = newDelta > 20;
      let newNavbarFixed = newDelta < dlt || newDelta < 10;
      dlt = newDelta;
      setNavbarFixed(newNavbarFixed);
      setNavbarBackground(newNavbarBackground);
      setDelta(newDelta * 2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="ActivityDetail">
      <NavBar navbarFixed={navbarFixed} navbarBackground={navbarBackground} />
      <div className='title'>
        <p className='title_1'>{title}</p>
        <p className='title_2'>{subtitle}</p>
      </div>
      <div className='image'>
        <img className='main' src={image} alt={title + " " + subtitle} style={{ top: - (delta / 2) - (mainImageThreshold || 140) }} />
        <img className='mask' src={whiteTriangle} />
      </div>
      <div className='content'>
        <div className='title mw600'>
          <img src={blueRectangle} className="blue_rectangle" alt={title} />
          <div className='text'>{contentTitle}</div>
        </div>
        <div className='text'>{children}</div>
      </div>
      <div className='video'>
        <img src={linePattern1} className="line_pattern_1" alt="More" />
        <img src={linePattern2} className="line_pattern_2" alt="More" />
        <div className='inner'>
          <iframe src={videoUrl} title={title + " " + subtitle} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div className='contact'>
        <div className='inner'>
          <p className='title'>Contact</p>
          <div className='contacts-list'>
            <p className='link number'><a href='tel://+33632702384'><img src={phoneIcon} className="phone" alt="More" />+33 6 32 70 23 84</a></p>
            <p className='link mail'><a href='mailto:assohappyfeet@gmail.com'><img src={mailIcon} className="phone" alt="More" />assohappyfeet@gmail.com</a></p>
            <p className='link fb'><a href='https://www.facebook.com/olivier.happyfeet' target="_blank"><img src={fbIcon} className="fb" alt="More" />Toute notre actu sur Facebook !</a></p>
          </div>
        </div>
      </div>
      <ParallaxImage id="triangle1" noMobile={true} image={triangle5} properties={{ zIndex: 20, top: (80 - delta / 2) + "px", right: "15%" }} />
      <ParallaxImage id="triangle2" image={triangle7} properties={{ zIndex: 20, top: (580 - delta) + "px" }} />
      <ParallaxImage id="triangle3" image={triangle6} properties={{ zIndex: 20, top: (370 - delta / 3) + "px" }} />
      <ParallaxImage id="triangle4" noMobile={true} image={triangle5} properties={{ zIndex: 0, top: (1100 - delta / 2) + "px", left: "20%" }} />
      <ParallaxImage id="triangle5" image={triangle6} properties={{ zIndex: 20, top: (2100 - delta) + "px", right: "15%" }} />
    </div>
  );
}

export default ActivityDetail;
