import './Header.css';
import headerImg from '../../../asset/image/home_header_image.png';
import happyFeet from '../../../asset/image/happy_feet_round_text.svg';
import { ReactComponent as RightArrow } from '../../../asset/image/arrow_right_white.svg';
import LinkButton from '../../common/link_button/LinkButton';

function Header() {
  return (
    <div id='Header' className="Header">
      <div className='left'>
        <p className='title'>L'association</p>
        <p className='name'><img src={happyFeet} /></p>
        <p className='punchline'>Dansez comme vous êtes !</p>
        <p className='description'>Nos stages vous feront découvrir les danses en ligne, les claquettes ou les percussions corporelles dans une ambiance conviviale et décontractée ! Olivier vous partagera sa conception de la pédagogie, qui repose sur le plaisir, la prise en compte du rythme d’apprentissage et du style de chacun.
        </p>
        <LinkButton label={"Découvrez nous !"} link={"/us"} />
      </div>
      <div className='right'>
        <img src={headerImg} className="header_image" alt="Happy Feet" />
      </div>
    </div>
  );
}

export default Header;
