import './TapDanceDetail.css';
import mainTapDanceImage from '../../asset/image/tap_dance_main.jpg';
import ActivityDetail from '../common/activity_detail/ActvityDetail';
import Services from '../common/services/Services';


function TapDanceDetail() {
  return (
    <div className="TapDanceDetail" >
      <ActivityDetail title="Les" subtitle="claquettes" image={mainTapDanceImage} contentTitle="Les claquettes sont tout sauf rétro!" videoUrl="https://www.youtube.com/embed/Gul3REAAm1w" mainImageThreshold={-10}>
        <div className='mw600'>
          <p>L'association Happy Feet vous offre l'opportunité de découvrir les claquettes autrement ! Venez danser sur des rythmes inattendus et nouveaux, aux multiples influences (jazz, swing, funky, afro, reggae, salsa..) sans perdre de vue que la tapdance est avant tout un plaisir et une détente !</p>
          <p>Les stages et cours ont lieu dans la région Nantaise (Vigneux de Bretagne et la Chapelle-sur-Erdre), et sont ouverts à tous, quel que soit votre niveau.</p>
        </div>
        <Services>
          <div className='subtitle'>Programme de l’année</div>
          <div className='items'>
            <ul>
              <li><span className='emphasize'>Stages mensuels</span></li>
              <li><span className='emphasize'>Dîners dansants</span> (démonstrations)</li>
            </ul>
            <div className='subtitle'>Autres animations</div>
            <ul>
              <li><span className='emphasize'>Événementiel :</span> Team building – Séminaires</li>
              <li><span className='emphasize'>Événements publics :</span> Fête de la Musique, fête de quartier,…</li>
              <li><span className='emphasize'>Événements privés :</span> Bal de Mariage, anniversaire,…</li>
              <li><span className='emphasize'>Lieux :</span> Bars dansants, Maisons des séniors, campings, ...</li>
              <li>Cours particuliers ou en mini groupe</li>
            </ul>
            <div className='caption'>
              <p>* Olivier peut se déplacer à domicile ; il est ouvert à toute autre proposition dansante ou percussive qui vous est spécifique ! N’hésitez pas à le contacter !</p>
              <p>De par sa formation, Olivier est à même de proposer des ateliers "claquettes" ou "percussions corporelles" adaptés à un public en situation d'handicap visuel.</p>
            </div>
          </div>
        </Services>
      </ActivityDetail>
    </div>
  );
}

export default TapDanceDetail;
