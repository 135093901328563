import './RelevantNumber.css';

function RelevantNumber({ number, unit }) {

  return (
    <div className='RelevantNumber'>
      <div className='number'>{number}</div>
      <div className='unit'>{unit}</div>
    </div>
  );
}

export default RelevantNumber;
