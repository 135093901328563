import './LineDanceDetail.css';
import mainLineDanceImage from '../../asset/image/line_dance_main.jpg';
import ActivityDetail from '../common/activity_detail/ActvityDetail';
import Services from '../common/services/Services';
import RelevantNumber from '../common/relevant_number/RelevantNumber';


function LineDanceDetail() {
  return (
    <div className="LineDanceDetail" >
      <ActivityDetail title="Les danses" subtitle="en ligne" image={mainLineDanceImage} contentTitle="Danser ensemble dans la joie et la convivialité" videoUrl="https://www.youtube.com/embed/yaInVpA1fXQ" mainImageThreshold={50}>
        <div className='mw600'>
          <div className='numbers'>
            <RelevantNumber number={11} unit={"stages/an"} />
            <RelevantNumber number={3} unit={"dîners dansants /an"} />
          </div>
          <p>Ne vous méprenez pas, la danse en ligne n’est pas une danse qui s’apprend sur Internet !
            La danse en ligne correspond à <span className='enhanced'>une chorégraphie assez courte</span> (de 16 à 64 temps, qui se répètent sur 2 ou 4 « murs ») adaptée à une musique spécifique
            (ou un type de musique) et qui se danse <span className='enhanced'>sur une ou plusieurs lignes ou en cercle</span>.</p>
          <p>Même s’il existe plusieurs niveaux en danses en ligne, leur succès grandissant auprès d’un public de tous âges et de tous horizons s’explique par leur accessibilité, leur grande diversité et par la joie communicative qu’elle procure du fait de danser seul mais dans un même mouvement collectif.</p>
          <p>Olivier, de par sa jovialité, son énergie et sa passion pour la transmission et pour les danses du monde entier vous emportera dans <span className='enhanced'>un univers joyeux et coloré</span>.Voici une liste (non exhaustive) des styles de danses en ligne qui vous seront proposées (Carquefou, Vigneux de Bretagne / La Paquelais, La Chapelle-sur-Erdre) :</p>
          <ul>
            <li>Madison</li>
            <li>Jerusalema</li>
            <li>Kuduro</li>
            <li>Country</li>
            <li>Sirtaki</li>
            <li>Cumbia</li>
            <li>Salsa</li>
            <li>Funk</li>
            <li>Irish dance</li>
            <li>Bollywood</li>
            <li>Afro</li>
            <li>Line dance (chorégraphies adaptées à des musiques actuelles)</li>
            <li>etc…</li>
          </ul>
        </div>
        <Services>
          <div className='subtitle'>Programme de l’année</div>
          <div className='items'>
            <ul>
              <li><span className='emphasize'>Stages mensuels</span></li>
              <li><span className='emphasize'>Dîners dansants</span></li>
            </ul>
            <div className='linkContainer'>
              <a className='link' href="https://www.facebook.com/olivier.happyfeet" target='_blank'>Programme & inscriptions</a>
            </div>
            <div className='subtitle'>Autres animations</div>
            <ul>
              <li><span className='emphasize'>Événementiel :</span> Team building – Séminaires</li>
              <li><span className='emphasize'>Événements publics :</span> Fête de la Musique, fête de quartier, flashmobs,…</li>
              <li><span className='emphasize'>Événements privés :</span> Bal de Mariage, anniversaire,…</li>
              <li><span className='emphasize'>Lieux :</span> Bars dansants, Maisons des séniors, campings, ...</li>
              <li>Cours particuliers ou en mini groupe</li>
              <li>Animations scolaires, préparation à des démonstrations (fêtes des écoles...)</li>
            </ul>
            <div className='caption'>
              <p>* Olivier peut se déplacer à domicile ; il est ouvert à toute autre proposition dansante ou percussive qui vous est spécifique ! N’hésitez pas à le contacter !</p>
              <p>De par sa formation, Olivier est à même de proposer des ateliers "claquettes" ou "percussions corporelles" adaptés à un public en situation d'handicap visuel.</p>
            </div>
          </div>
        </Services>
      </ActivityDetail>
    </div>
  );
}

export default LineDanceDetail;
