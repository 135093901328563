import './More.css';
import moreImg from '../../../asset/image/more.jpg';
import linePattern1 from '../../../asset/image/line_pattern_bg_1.png';
import linePattern2 from '../../../asset/image/line_pattern_bg_2.png';

function More() {
  return (
    <div className="More">
      <img src={linePattern1} className="line_pattern_1" alt="More" />
      <img src={linePattern2} className="line_pattern_2" alt="More" />
      <div className='inner'>
        <div className='left'><img src={moreImg} className="more_image" alt="More" /></div>
        <div className='right'>
          <p className='title_1'>Encore plus de danse ?</p>
          <p className='title_2'>Les dîners dansants</p>
          <p className='description'>Au programme : de la musique, de la bonne humeur, du rire avec ses éclats, un bon repas et bien sûr la communion par le plaisir de la danse (en ligne) partagée !</p>
          <p className='punchline'><a href='https://www.facebook.com/olivier.happyfeet' target='_blank' alt="">Retrouvez toutes les dates sur la page Facebook !</a></p>
        </div>
      </div>
    </div>
  );
}

export default More;
