import { useEffect, useState } from 'react';
import './Services.css';
import blueRectangle from '../../../asset/image/blue_rectangle.svg';

function Services({children}) {

  return (
      <div className='Services'>
        <div className='inner mw600'>
          <div className='title'>
            <img src={blueRectangle} className="blue_rectangle" alt="" />
            <div className='text'>Nos prestations*</div>
          </div>
          <div>{children}</div>
        </div>
      </div>
  );
}

export default Services;
