import './LinkButton.css';
import { ReactComponent as RightArrow } from '../../../asset/image/arrow_right_white.svg';

function LinkButton({ label, link }) {

  return (
    <div className='LinkButton'>
      <a href={link} target="_blank" className='inner_link'>
        {label}<RightArrow className='img' stroke="#EE7601" />
      </a>
    </div>
  );
}

export default LinkButton;
