import './NavBar.css';
import logo from '../../../asset/image/logo.svg';
import logoLine from '../../../asset/image/logo_line.svg';
import arrowDown from '../../../asset/image/arrow_down.svg';
import menu from '../../../asset/image/menu.svg';
import close from '../../../asset/image/close.svg';
import fb from '../../../asset/image/fb.svg';
import { useState } from 'react';
import blueRectangle from '../../../asset/image/blue_rectangle.svg';

function NavBar({ navbarFixed, navbarBackground }) {
  const [isShownDropdown, setIsShownDropdown] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggleMenu = () => setIsOpenMenu(!isOpenMenu)

  return (
    <div className={"NavBar" + (navbarFixed ? " fixed" : "") + (navbarBackground ? " opaque-background" : "") + (isOpenMenu ? " menuOpened" : "")}>
      <div className='inner mobileHidden'>
        <div className='left'><a href='/'><img src={logo} className="App-logo" alt="logo" /></a></div>
        <nav className='links'>
          <ul>
            <li><a href='/us'>Qui sommes-nous ?</a></li>
            <li onMouseEnter={() => setIsShownDropdown(true)} onMouseLeave={() => setIsShownDropdown(false)} className={'activities-dropdown' + (isShownDropdown ? " open" : "")}>
              <span className='activities-dropdown-title'>Nos activités<img src={arrowDown} className="arrow-down" alt="" /></span>
              <ul className='activities-dropdown_list'>
                <li><a href="/line">Danses en ligne</a></li>
                <li><a href="/tap">Claquettes</a></li>
                <li><a href="/body">Percussions corporelles</a></li>
              </ul>
            </li>
            <li><a href='/#Where'>Contactez nous</a></li>
            <li><a href='https://www.facebook.com/olivier.happyfeet' target="_blank"><img src={fb} alt="" /></a></li>
          </ul>
        </nav>
      </div>
      <div className='inner mobileOnly'>
        <div className='left' onClick={(e) => toggleMenu(e)}>
          <img src={isOpenMenu ? close : menu} className="menu" alt="" />
        </div>
        <div className='center'><a href='/'><img src={logoLine} className="App-logo" alt="logo" /></a></div>
        <nav className='links'>
          <ul>
            <li><a href='https://www.facebook.com/olivier.happyfeet' target="_blank"><img src={fb} alt="" /></a></li>
          </ul>
        </nav>
      </div>

      <div className='menuContent'>
        <ul>
          <li><a href='/us'>Qui sommes-nous ?</a></li>
          <li><img src={blueRectangle} className="blue_rectangle" alt={""} /></li>
          <li><a href="/line">Danses en ligne</a></li>
          <li><a href="/tap">Claquettes</a></li>
          <li><a href="/body">Percussions corporelles</a></li>
          <li><img src={blueRectangle} className="blue_rectangle" alt={""} /></li>
          <li><a href='/#Where'>Contactez nous</a></li>
        </ul>
      </div>
    </div>
  );
}

export default NavBar;
