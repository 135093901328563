import './Activities.css';
import Activity from './components/activity/Activity';
import img1 from '../../../asset/image/home_activities_1.png';
import img2 from '../../../asset/image/home_activities_2.png';
import img3 from '../../../asset/image/home_activities_3.png';

function Activities() {
  return (
    <div className="Activities">
      <div className='title'>
        <p className='title_1'>Découvrez nos</p>
        <p className='title_2'>activités</p>
      </div>
      <div className='content'>
        <Activity image={img1} header={"Stages de"} title={"Danses en ligne"} link="https://assohappyfeet.fr/line" description={"La danse en ligne correspond à une chorégraphie assez courte adaptée à une musique spécifique (ou un type de musique) et qui se danse sur une ou plusieurs lignes ou en cercle. "} />
        <Activity image={img2} header={"Stages de"} title={"Claquettes"} link="https://assohappyfeet.fr/tap" description={"Les claquettes américaines c'est avant tout la joie de la danse mêlée à celle des percussions ; c'est aussi une discipline redevenue moderne !"} />
        <Activity image={img3} header={"Ateliers de"} title={"Percussions corporelles"} link="https://assohappyfeet.fr/body" description={"A mi-chemin entre la danse et la musique, elles sont basées sur la notion que le corps entier est source de percussion !"} />
      </div>
    </div>
  );
}

export default Activities;
