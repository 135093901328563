import './BodyTapDetail.css';
import mainBodyPercussionsImage from '../../asset/image/body_percussions_main.jpg';
import ActivityDetail from '../common/activity_detail/ActvityDetail';
import Services from '../common/services/Services';


function BodyTapDetail() {
  return (
    <div className="LineDanceDetail" >
      <ActivityDetail title="Les percussions" subtitle="corporelles" image={mainBodyPercussionsImage} contentTitle="Le corps comme instrument de musique" videoUrl="https://www.youtube.com/embed/4Ooq6xRVToE" mainImageThreshold={50}>
        <div className='mw600'>
          <p>Le concept est simple, les <span className='enhanced'>percussions corporelles</span> peuvent se définir comme un <span className='enhanced'>genre musical ancestral et multiculturel</span>, qui consiste à produire des mélodies ou des rythmes en utilisant le corps comme instrument de musique.</p>
          <p>Discipline facile à mettre en oeuvre car ne nécessitant aucun matériel, elle est par essence ludique mais pas seulement. Les objectifs pédagogiques sont riches et multiples :</p>
          <ul>
            <li>Promouvoir l’étude du large répertoire de sons qui peuvent être produits par le corps.</li>
            <li>Stimuler le contact avec notre propre corps.</li>
            <li>Promouvoir l’assimilation de ces sons corporels avec l’intention de produire des rythmes et des mélodies.</li>
            <li>Stimuler la capacité d’écoute, de mémorisation, de concentration, et l’assimilation de rythmes dans le corps.</li>
            <li>Développer la coordination motrice et la perception rythmique, mélodique et harmonique.</li>
            <li>Développer la capacité de création musicale spontanée, tant individuellement que dans un groupe.</li>
            <li>Éveiller les participants à une attitude de jeu en équipe, en les motivant pour s’exprimer musicalement dans un groupe, tout en prenant en compte les qualités et potentiels différents de chacun.</li>
          </ul>
          <p>Cette discipline peut être associée aux <span className='enhanced'>percussions sur objets</span> (gobelets, boomwhackers, baton-baguette, balles de basket) qui rajoutent au côté ludique de la pratique rythmique et percussive.</p>
        </div>
        <Services>

          <div className='items'>
            <div className='subtitle'>Animations</div>
            <ul>
              <li><span className='emphasize'>Stages</span> ponctuels</li>
              <li><span className='emphasize'>Cours particuliers</span> ou en mini groupe</li>
              <li><span className='emphasize'>Animations scolaires,</span> préparation à des démonstrations (fêtes des écoles...)</li>
              <li><span className='emphasize'>Événementiel :</span> Team building – Séminaires</li>
              <li><span className='emphasize'>Animations</span> au sein d'autres associations : Danse, théâtre, chant, percussions,...</li>
            </ul>
            <div className='caption'>
              <p>* Olivier peut se déplacer à domicile ; il est ouvert à toute autre proposition dansante ou percussive qui vous est spécifique ! N’hésitez pas à le contacter !</p>
              <p>De par sa formation, Olivier est à même de proposer des ateliers "claquettes" ou "percussions corporelles" adaptés à un public en situation d'handicap visuel.</p>
            </div>
          </div>
        </Services>
      </ActivityDetail>
    </div>
  );
}

export default BodyTapDetail;
