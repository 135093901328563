import './Teacher.css';
import teacher from '../../asset/image/teacher.png';
import { useEffect, useState } from 'react';
import NavBar from '../common/navbar/NavBar';
import triangle1 from '../../asset/image/triangle_1.svg';
import triangle2 from '../../asset/image/triangle_2.svg';
import triangle3 from '../../asset/image/triangle_3.svg';
import blueRectangle from '../../asset/image/blue_rectangle.svg';
import ParallaxImage from '../common/parallax_image/ParallaxImage';
import Like from './components/Like';
import LinkButton from '../common/link_button/LinkButton';


function Teacher() {
  const [delta, setDelta] = useState(0);
  const [navbarFixed, setNavbarFixed] = useState(true);
  const [navbarBackground, setNavbarBackground] = useState(false);

  var dlt = 0;

  useEffect(() => {
    const handleScroll = event => {
      let newDelta = window.scrollY / 4;
      let newNavbarBackground = newDelta > 20;
      let newNavbarFixed = newDelta < dlt || newDelta < 10;
      dlt = newDelta;

      setNavbarFixed(newNavbarFixed);
      setNavbarBackground(newNavbarBackground);
      setDelta(newDelta * 2);
      console.log("scroll 2", newNavbarFixed)
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="Teacher" >
      <NavBar navbarFixed={navbarFixed} navbarBackground={navbarBackground} />
      <div className="inner">
        <div className="inside">
          <div>
            <ParallaxImage id="triangle1" image={triangle1} properties={{ top: (-20 - delta / 2) + "px" }} />
            <ParallaxImage id="triangle4" image={triangle3} properties={{ zIndex: "0", top: (850 - delta / 2) + "px" }} />
            <ParallaxImage id="triangle5" image={triangle2} properties={{ zIndex: "0", top: (2020 - delta * 2) + "px", left: "7%" }} />
            <ParallaxImage id="triangle6" image={triangle2} properties={{ zIndex: "20", top: (20 - delta) + "px" }} />
            <ParallaxImage id="triangle7" image={triangle3} properties={{ zIndex: "20", top: (530 - delta * 2) + "px", right: "20%" }} />
            <ParallaxImage id="triangle9" image={triangle2} properties={{ zIndex: "20", top: (2300 - delta) + "px", right: "50%" }} />
          </div>
          <div id='Header' className="Header">
            <div className='left'>
              <p className='title'>Qui sommes-</p>
              <p className='name'>nous ?</p>
              <p className='description'>« Danseur chorégraphe éclectique, claquettiste, percussionniste corporel et sur objets, "diffuseur de joie"... C'est ainsi que je peux me présenter ! »</p>
              <LinkButton label={"Contactez nous"} link={'https://www.facebook.com/olivier.happyfeet'} />
            </div>
            <div className='right'>
              <img src={teacher} className="header_image" alt="Happy Feet" />
            </div>
          </div>
          <div className='content'>
            <div className='title blue'>
              <img src={blueRectangle} className="blue_rectangle" alt="" />
              <div className='text'>Olivier, un professeur joyeux et passionné !</div>
            </div>
            <div className='text'>
              <p>Passionné depuis toujours par la musique, la danse, le rythme et les percussions, attiré par le fait de transmettre, c’est tout naturellement qu’Olivier a pris les fonctions de professeur de claquettes américaines, de percussions corporelles et sur objets, et enfin  de « danses en ligne », avec un enthousiasme toujours renouvelé.
              </p><p>
                Touche à tout dans l’âme, Olivier a commencé par se former aux danses de salon (rock et danses latines) tout en se passionnant pour l’art de la jonglerie, discipline par laquelle il fera ses « premières armes » en tant qu’animateur au sein de centres de vacances.
                En 1991, Olivier découvre les claquettes américaines puis les claquettes irlandaises : un déclic immédiat, une évidence !
              </p><p>
                Après dix ans de cours réguliers et de stages internationaux auprès de grands claquettistes et chorégraphes (Sharon LAVI, Max POLLAK, Fabien RUIZ, Guillhem ALONSO, Jeremie CHAMPAGNE, Dorel et Costel SURBECK,...), Olivier décide de trasmettre à son tour, l’art des claquettes en créant l’Association Happy Feet.
              </p><p>
                En parallèle, Olivier fidèle à son éclectisme, continue de se former aux danses israëliennes et africaines, aux percussions africaines et brésiliennes qu’il pratique durant sept ans au sein de la batucada  internationale Batala (troupe de déambulation jouant les rythmes du samba reggae).
              </p><p>
                En 2010, Olivier découvre et se passionne (par les groupes STOMP et BARBATUQUES) pour les percussions corporelles et sur objets, activité de coordination à la fois rythmique et ludique qu’il perfectionne lors de multiples stages internationaux auprès des plus grands maîtres (Keith TERRY, Leela PETRONIO, Jep MELENDEZ, Fernando BARBA, Tupac MANTILLA,…).
              </p><p>
                En 2016, Olivier décide de s’ouvrir à un nouveau public en proposant des cours de « danses en ligne » enrichies de toutes ses influences musicales (country, madison, kuduro, jerusalema, cumbia, irish dance, bollywood,…) ; le succès est au rendez-vous avec un triplement des effectifs dés la seconde saison ; aujourd’hui, Olivier compte une centaine d’élèves participant de façon régulière aux stages et dîners dansants qu’il anime.
              </p><p>
                Sa conception de la pédagogie repose avant tout sur le plaisir de danser ensemble en respectant le rythme d’apprentissage et le style propres à chacun et chacune.
                L’eclectisme, la jovialité, le dynamisme et l’enthousiasme d’Olivier font de ses prestations des moments ludiques, festifs et conviviaux fort appréciés.
              </p>
            </div>
          </div>
          <div className='likes'>
            <div className='title blue'>
              <img src={blueRectangle} className="blue_rectangle" alt="" />
              <div className='text'>Pourquoi on aime ?</div>
            </div>
            <div className='likesContent'>
              <div className='line'>
                <Like author={"Brigitte SAPIENCE"} stars={5} message={"Je prends un cours de claquettes américaines avec Happy Feet, le bien-nommé. Olivier Bouchaud, le professeur, nous communiqué son entrain et son humour de cours en cours. Son dynamisme et son professionnalisme nous font progresser très vite. J'adore ce cours, pendant 1h qui passe toujours trop vite, j'oublie tout le reste. Une belle parenthèse dans la bonne humeur !"} />
                <Like author={"Nathalie B"} stars={5} message={"La danse en ligne, ce n'est pas seulement le madison, il y a aussi toutes sortes de danses, de l'irlandaise à l'afro, en passant par le rock, la country, la salsa et même le hip-hop ! Et avec un prof comme Olivier, comment ne pas s'éclater :) Toujours la pêche, de bonne humeur, d'une gentillesse absolue, tout en étant très professionnel. Le mardi soir, c'est mon petit instant détente/plaisir de la semaine (même si parfois on sent bien nos mollets travailler)."} />
              </div>
              <div className='line'>
                <Like author={"Elo Die"} stars={3} message={"J’ai découvert la danse en ligne il y a 2 ans et je ne pourrai m’en passer ! Esprit d’équipe, entraide, beauté des chorégraphies sur une musique rythmée.. quel bonheur ! Et avec un prof comme Olivier, tellement sympathique, bienveillant et pédagogue..  quelle joie !"} />
                <Like author={"Karen Frioux"} stars={3} message={"Si tu est fatiguée si tu es triste vas-y fonce-y. Il donne de la bonne humeur de l'énergie top top top super  Les cours merci."} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teacher;
