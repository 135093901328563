import './StarRating.css';

function StarRating({ score }) {
  return (
    <div className="StarRating">
      {[...Array(score)].map((star) => {        
        return (         
          <span className="star">&#9733;</span>        
        );
      })}
    </div>
  );
}

export default StarRating;
